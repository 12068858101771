@use '../../variables';
.appShellContainer {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  //width: 100vw;
  //margin: 0 auto;
  //padding: 0 auto;
}
.appBarStyle {
  height: variables.$headerHeight;
}

body {
  margin: 0 auto !important;
}

.print-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: variables.$fancyColor;
  padding: 1.5rem;
  img {
    width: 220px;
  }
  svg {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
