@use '../../variables';
.scanner-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - (variables.$headerHeight));
  height: calc((var(--vh, 1vh) * 100) - (variables.$headerHeight));
  width: 100%;
  background-color: variables.$primaryColor;
}

.footer {
  height: variables.$footerHeight;
}
